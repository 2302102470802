define('ember-wordpress/models/post', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	var Model = _emberData['default'].Model;
	var attr = _emberData['default'].attr;
	var hasMany = _emberData['default'].hasMany;

	exports['default'] = Model.extend({
		title: attr('string'),
		content: attr('string'),
		excerpt: attr('string'),
		slug: attr('string'),
		date: attr('date'),
		featured_media: attr('number'),
		format: attr(),
		categories: hasMany('category', { async: true }),
		tags: hasMany('tag', { async: true }),
		acf: attr()
	});
});