define('ember-wordpress/adapters/wordpress', ['exports', 'ember-data', 'ember-get-config'], function (exports, _emberData, _emberGetConfig) {
	'use strict';

	// The WP API requires a rest adapter.
	exports['default'] = _emberData['default'].RESTAdapter.extend({
		host: _emberGetConfig['default'].emberWordpress.host,
		coalesceFindRequests: _emberGetConfig['default'].emberWordpress.coalesceFindRequests || false,
		// This is the default namespace for WP API v2.
		namespace: 'wp-json/wp/v2',

		handleResponse: function handleResponse(status, headers, payload, requestData) {
			// Wordpress sends the 'meta' data (useful for pagination) in the GET requests headers.
			// Here we move it into a `meta` property like Ember expects.
			if (payload) {
				var meta = {
					total: headers['X-WP-Total'],
					totalPages: headers['X-WP-TotalPages']
				};
				payload.meta = meta;
			}
			return this._super(status, headers, payload, requestData);
		}
	});
});