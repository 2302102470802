define('ember-wordpress/models/term', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	var attr = _emberData['default'].attr;

	exports['default'] = _emberData['default'].Model.extend({
		count: attr('number'),
		description: attr('string'),
		link: attr('string'),
		name: attr('string'),
		slug: attr('string'),
		taxonomy: attr('string'),
		parent: attr('number')
	});
});